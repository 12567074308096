<template>
  <div id="bloc" class="after-inner">
    <article class="wrapper" id="detail">
      <section id="main">
        <div class="wrap">
          <div class="con">
            <div class="title">
              <h3 class="f-36">{{ news.articleTitle }}</h3>
              <span class="date en-m mc f-24"
                >{{ $t("news.date") }}:{{ news.createTime }}</span
              >

              <div class="bqxx">
                <span v-if="news.articleLabel" class="bqxx-1">{{
                  news.articleLabel
                }}</span>
              </div>
              <span class="line"></span>
            </div>

            <div class="contentss">
              <div></div>
              <div class="content" v-html="news.articleContents"></div>
            </div>

            <div v-if="news.articleVideo">
              <div class="jianji">视频剪辑</div>
              <video
                height="300"
                width="100%"
                controls
                :src="news.articleVideo"
              ></video>
            </div>
          </div>
        </div>
      </section>
      <section id="btm"></section>
    </article>
  </div>
</template>

<script>
export default {
  metaInfo: {
    // title: "新闻列表", // set a title
    link: [
      {
        // set link
        rel: "asstes",
        href: "www.baidu.com",
      },
    ],
  },

  data() {
    return {
      news: {},
      code: 1,
    };
  },
  created() {
    window.document.title = this.$t("news.title");
    let { id } = this.$route.query;
    this.code = id;
    this.getNews(this.code);
  },
  computed: {
    language() {
      return this.$store.state.Language;
    },
  },
  watch: {
    language(newVal, oldVal) {
      window.document.title = this.$t("news.title");
      this.getNews(this.code);
    },
  },
  methods: {
    getNews(id) {
      let language = this.$store.state.Language;
      this.$http
        .get("/newList/list?code=" + id + "&language=" + language)
        .then((res) => {
          // console.log(res);
          this.news = res.rows[0];

          // 使用DOMParser来解析HTML字符串
          let doc = new DOMParser().parseFromString(this.news.articleContents, 'text/html');
          let images = doc.getElementsByTagName('img');
          for (let i = 0; i < images.length; i++) {
            images[i].style.maxWidth = '100%'
            images[i].style.objectFit = 'contain'           
          }
          this.news.articleContents = doc.documentElement.outerHTML
        })
        .catch((err) => {
          console.log("请求数据失败");
        });
    },
    returnNewList() {
      this.$router.push({ path: "/newList" });
    },
  },
};
</script>

<style scoped lang="scss">
.bqxx {
  text-align: center;
  margin-top: 20px;
}
.bqxx-1 {
  margin: 5px;
  padding: 5px;
  border-radius: 8px;
  font-weight: 700;
  color: rgb(76, 76, 76);
  border: 2px solid rgb(221, 229, 240);
  background-color: rgb(221, 229, 240);
}

@media screen and (max-width: 1366px) {
  a,
  span,
  li,
  b,
  i,
  label,
  p,
  strong,
  div,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  small,
  em,
  li,
  pre,
  form,
  fieldset,
  legend,
  button,
  input,
  textarea,
  th,
  td {
    font-size: 14px;
    font-family: word;
  }
}

body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font: 12px "PingFang HK", "Microsoft YaHei", "Arial";
  color: #000;
  line-height: 1.5;
  overflow-x: hidden;
}

#detail {
  padding-top: 0.9rem;
  // background-color: #f5f5f6;
}

.after-inner .wrapper {
  padding-top: 0.9rem;
}

.wrapper {
  overflow: hidden;
  position: relative;
  z-index: 2;
}

#detail #main {
  position: relative;
}

.jianji {
  font-size: 15px;
  padding-bottom: 20px;
  padding-top: 20px;
}
.contentss {
  font-size: 15px;
  padding-bottom: 20px;
  padding-top: 20px;
}
#detail #main .wrap {
  position: relative;
  z-index: 5;
}

#detail #main .wrap .other {
  height: 6vw;
  position: relative;
  // width: 64%;
  max-width: 860px;
  // margin: auto;
}

#detail #main .wrap .other a {
  box-shadow: 0px 0px 4px #ccc;
  border-radius: 8px;
  text-align: end;
}

#detail #main .wrap .other a:hover {
  background: rgb(34, 81, 197);
  color: #fff;
}

#detail #main .wrap .other .return {
  background-color: rgb(255, 255, 255);
  padding: 10px 26px;
  text-align: center;
  position: absolute;
  display: inline-block;
  bottom: 20px;
  left: 0;
  color: black;
}

a {
  cursor: pointer;
}

#detail #main .wrap .con {
  width: 64%;
  max-width: 1200px;
  margin: auto;
  background-color: #fff;
  padding: 2rem 1rem;
  margin-top: 6vw;
  border-radius: 12px;
  margin-bottom: 6vw;
}
@media screen and (max-width: 750px) {
  #detail #main .wrap .con {
    width: 90%;
  }
}

#detail #main .wrap .con .title {
  position: relative;
}

#detail #main .wrap .con .title .date {
  height: 0.48rem;
  line-height: 0.48rem;
  margin-bottom: 0.24rem;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1280px) {
  .f-24 {
    font-size: 16px;
  }
}

@media screen and (max-width: 1366px) {
  .f-24 {
    font-size: 17px;
  }
}

@media screen and (max-width: 1500px) {
  .f-24 {
    font-size: 22px;
  }
}

.f-24 {
  font-size: 18px;
}

.f-36 {
  display: flex;
  align-items: center;
  justify-content: center;
}

#detail #main .wrap .con .title {
  position: relative;
}

#detail #main .wrap .con .title .line {
  margin-top: 0.5rem;
}

.line {
  width: 100%;
  height: 1px;
  display: inline-block;
}

.mr-10 {
  margin-right: 10px;
  cursor: pointer;
  color: #141414 !important;
}

a {
  text-decoration: none;
}

.content {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
</style>
